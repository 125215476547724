import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import BannerServices from '../components/BannerServices';

import networkAdministrationPicture from '../assets/images/services/networkAdministration.jpg';
import serverRepairPicture from '../assets/images/services/serverRepair.jpg';
import vpnPicture from '../assets/images/services/vpn.jpg';
import dataRecoveryPicture from '../assets/images/services/dataRecovery.jpg';
import securityPicture from '../assets/images/services/security.jpg';
import equipmentLeasingPicture from '../assets/images/services/equipmentLeasing.png';
import wiringPicture from '../assets/images/services/wiring.png';

const Services = () => (
  <Layout>
    <Helmet>
      <title>Services</title>
      <meta name="description" content="IT projects can sometimes be overwhelming, our team of experts will help your project finish on time, and stay within budget." />
    </Helmet>

    <BannerServices />

    <div id="main">
      <section id="one" className="spotlights">
        <section>
          <img
            src={networkAdministrationPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Network Administration</h3>
              </header>
              <p>Many companies use outdated systems that really slow down the network. Though all the computers being used are brand new, they are bottlenecked in the server room, where all the wires meet. Let us help you maximize your work flow by evaluating the competency of your system in place and seeing what we can do to improve the overall network speed. We can also build a network from scratch, whether it is wired or wireless, and help keep your information where it belongs: with you.</p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={serverRepairPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>New Server / PC Repair</h3>
              </header>
              <p>
PC Repair: Let our trained professionals analyze your system for malicious software that may be slowing down your work force. We specialize in repairing all aspects of all computers including Macintosh, PC’s, and servers from software issues to hardware problems.
                <br />
                <br />
New Servers: Many business owners do not realize the value of having a server until they see it in action. We offer servers for every type of business, whether you have 1 employee or 10,000 employees. The most essential benefits of having a machine like this is a centralized location for all your data, making it easier to access for your company, but also making it easier to back up and restore in the event of a disaster. Using centralized applications such as Microsoft Exchange and Sharepoint also help you allocate the time of your employees better by having a synchronized system in which everyone can show what they are doing with their time, such as meetings or expressing project deadlines.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={vpnPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>VPN / Remote Access</h3>
              </header>
              <p>Most people often want to spend more time with their family rather than be at the office all the time. We help make this happen by setting up very secure remote access systems, where employees can log into their office computer from home via VPN (virtual private network). This allows them to take their work wherever they go. We also install and maintain multiple office to office VPN solutions designed to allow data and voice communication between remote offices.</p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={dataRecoveryPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Data Recovery</h3>
              </header>
              <p>In the tragic event of data corruption or hardware failure resulting in data loss, our experts are trained and committed in helping you recover the data you need. We specialize in recovering data from RAID subsystems, workstation computers, Linux/Windows servers, and all types of flash drives. If there is data on it, we will recover it.</p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={securityPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Secure IT</h3>
              </header>
              <p>
Digital data is the lifeline of most companies, and having that data secure can mean the difference between success and failure.
                <br />
                <br />
Our Secure IT program is designed to protect your data and keep it secure from unauthorized access. Our program begins with a security audit of the current environment, a planned implementation of any changes, and proactive monitoring, which resolves issues before you know about them. We like to focus on disaster prevention in every area rather than offering solutions to fix major problems after the fact.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={equipmentLeasingPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Equipment Leasing</h3>
              </header>
              <p>Whether you want a custom built computer or want to order through many of our partner vendors, such as Dell, we cater to all your needs. We are always ready to recommend the “best bang for your buck” pc, and can help you finance or lease the equipment, leaving your company with more cash flow.</p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={wiringPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Wiring</h3>
              </header>
              <p>We provide low voltage wiring for all types of applications: network infrastructure, home automation, new construction, remodeling, or if you simply need a custom ethernet cable for your network application.</p>
            </div>
          </div>
        </section>
      </section>
    </div>

  </Layout>
);

export default Services;
